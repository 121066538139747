
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import Home from "../main/home/home";
import Request from "../main/request/request";
import Perview from "../main/request/perview";
import Thank from "../main/request/thank_you";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route index element={<Home />} />
        <Route exact path="/req" element={<Request />} />
        <Route exact path="/perview" element={<Perview />} />
        <Route exact path="/thank-you" element={<Thank />} />
        <Route
      path="*"
      element={<Navigate to="/" replace={true} />}
    />
      </Routes>
    </BrowserRouter>
  );
}