import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';



// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function StickyFooter() {
    const [seconds, setSeconds] = React.useState(8); // Initial time in seconds
    const redirectUrl = "/"; // URL to redirect after timer
  
    React.useEffect(() => {
      const timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds - 1);
      }, 1000);
  
      // Redirect when the timer reaches 0
      if (seconds === 0) {
        clearInterval(timer);
        window.location.href = redirectUrl;
      }
  
      // Cleanup function to clear interval when component unmounts
      return () => clearInterval(timer);
    }, [seconds]);
    
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <CssBaseline />
        <Container component="main" sx={{ mt: 8, mb: 2 }} maxWidth="sm">
          <Typography variant="h2" component="h1" gutterBottom>
            Thank you!
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom>
            {'We will contact you shortly'}
            
          </Typography>
          
        </Container>
        <Box
          component="footer"
          sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[200]
                : theme.palette.grey[800],
          }}
        >
        
        </Box>
      </Box>
    </ThemeProvider>
  );
}