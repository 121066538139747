
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MarkEmailUnread } from '@mui/icons-material';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };
  const [email, setEmail] = React.useState('');
  const [com, setCom] = React.useState('');
  const [name, setName] = React.useState('');
  const [mach, setmach] = React.useState('');
  const [model, setModel] = React.useState('');
  const [phone, setphone] = React.useState('');
  const [pro, setpro] = React.useState('');
  const [datum, setDatum] = React.useState('');
 

  const handleChange = (event) => {
    // setAge(event.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <Card  sx={{
          padding:"30px"
  }} >
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            
            
          </Avatar>
          <Typography component="h1" variant="h5">
          ExciPad Configurator
          </Typography>
          <p>A tool to configure and customize your ExciPad 2006 to your specifications</p>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(v)=>{setEmail(v.target.value)}}
              value={email}
              autoFocus
            />
            <TextField
              margin="normal"
              
              fullWidth
              name="company"
              label="Company (optional)"
              type="text"
              onChange={(v)=>{setCom(v.target.value)}}
              value={com}
              id="outlined-basic"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="company"
              label="Name/Brand"
              type="text"
              id="outlined-basic"
              onChange={(v)=>{setName(v.target.value)}}
              value={name}
            />
            <TextField
              margin="normal"
              
              fullWidth
              name=""
              label="Type of Machine (optional)"
              type="text"
              id="outlined-basic"
              onChange={(v)=>{setmach(v.target.value)}}
              value={mach}
            />
            <TextField
              margin="normal"
              
              fullWidth
              name="company"
              label="Model/s (optional)"
              type="text"
              onChange={(v)=>{setModel(v.target.value)}}
              value={model}
              id="outlined-basic"
            />
            <TextField
              margin="normal"
              
              fullWidth
              name="company"
              label="Phone No. (optional)"
              type="text"
              onChange={(v)=>{setphone(v.target.value)}}
              value={phone}
              id="outlined-basic"
            />
             {/* <div>
      <FormControl fullWidth sx={{ marginTop:'20px' }}>
        <InputLabel fullWidth id="demo-simple-select-helper-label">Select product</InputLabel>
        <Select
        fullWidth
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={age}
          label="Select product"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Product 1</MenuItem>
          <MenuItem value={20}>Product 2</MenuItem>
          <MenuItem value={30}>Product 1</MenuItem>
        </Select>
      
      </FormControl>
      
    </div> */}


            <TextField
              margin="normal"
              
              fullWidth
              name="company"
              label="Datum / rev (optional)"
              type="text"
              id="outlined-basic"
              onChange={(v)=>{setDatum(v.target.value)}}
              value={datum}
            />
            
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              onClick={()=>{
                if(name=="")
                {
                  alert("Please write your 'Name' first!")

                }else if(email=="")
                {
                  alert("Please write your 'Email' first!")
                }else{
                  localStorage.setItem("email",email)
                  localStorage.setItem("com",com)
                  localStorage.setItem("name",name)
                  localStorage.setItem("mach",mach)
                  localStorage.setItem("model",model)
                  localStorage.setItem("phone",phone)
                  localStorage.setItem("datum",datum)
                  window.location.href="/req"
                }

              }}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,backgroundColor:"black" }}
            >
              Create
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              {/* <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
        </Card>
      </Container>
    </ThemeProvider>
  );
}